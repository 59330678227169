import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../config";
import "./CreateRecipe.css";

const CreateRecipe = () => {
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [language, setLanguage] = useState("en"); // Default language is English
  const [slug, setSlug] = useState(""); 
  const [ingredients, setIngredients] = useState([{ name: "", quantity_unit: "" }]);
  const [instructions, setInstructions] = useState([{ step_number: "", step: "" }]);

  // New State for Valuable Info
  const [duration, setDuration] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [persons, setPersons] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle form submission
  const handleCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const recipeData = {
      title,
      image,
      description,
      category,
      language,
      slug,
      ingredients: ingredients.filter(
        (ingredient) => ingredient.name.trim() && ingredient.quantity_unit.trim()
      ),
      instructions: instructions.filter(
        (instruction) => instruction.step.trim() && instruction.step_number.trim()
      ),
      valuable_info_attributes: {
        duration: duration ? parseInt(duration) : null,
        difficulty,
        persons: persons ? parseInt(persons) : null
      }
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/recipes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
        body: JSON.stringify({ recipe: recipeData }),
      });

      const data = await response.json();

      if (response.ok) {
        navigate("/admin-recipes");
      } else {
        setError(data.error || "Failed to create recipe.");
      }
    } catch (error) {
      console.error("Error occurred during the API request:", error);
      setError("Network error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create-recipe">
      <h1>Create a New Recipe</h1>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleCreate}>
        <label>Title</label>
        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />

        <label>Image URL</label>
        <input type="text" value={image} onChange={(e) => setImage(e.target.value)} required />

        <label>Description</label>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />

        <label>Category</label>
        <input type="text" value={category} onChange={(e) => setCategory(e.target.value)} required />

        <label>Slug</label>
        <input type="text" value={slug} onChange={(e) => setSlug(e.target.value)} required />

        <label>Language</label>
        <select value={language} onChange={(e) => setLanguage(e.target.value)} required>
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="el">Greek</option>
        </select>

        {/* Valuable Info Section */}
        <h2>Recipe Details</h2>
        <label>Duration (Minutes)</label>
        <input type="number" value={duration} onChange={(e) => setDuration(e.target.value)} required />

        <label>Difficulty</label>
        <select value={difficulty} onChange={(e) => setDifficulty(e.target.value)} required>
          <option value="">Select Difficulty</option>
          <option value="Easy">Easy</option>
          <option value="Medium">Medium</option>
          <option value="Hard">Hard</option>
        </select>

        <label>Persons</label>
        <input type="number" value={persons} onChange={(e) => setPersons(e.target.value)} required />

        {/* Ingredients */}
        <label>Ingredients</label>
        {ingredients.map((ingredient, index) => (
          <div key={index}>
            <input
              type="text"
              placeholder="Ingredient Name"
              value={ingredient.name}
              onChange={(e) => {
                const updatedIngredients = [...ingredients];
                updatedIngredients[index].name = e.target.value;
                setIngredients(updatedIngredients);
              }}
              required
            />
            <input
              type="text"
              placeholder="Quantity"
              value={ingredient.quantity_unit}
              onChange={(e) => {
                const updatedIngredients = [...ingredients];
                updatedIngredients[index].quantity_unit = e.target.value;
                setIngredients(updatedIngredients);
              }}
              required
            />
            <button type="button" onClick={() => setIngredients(ingredients.filter((_, i) => i !== index))}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={() => setIngredients([...ingredients, { name: "", quantity_unit: "" }])}>
          Add Ingredient
        </button>

        {/* Instructions */}
        <label>Instructions</label>
        {instructions.map((instruction, index) => (
          <div key={index}>
            <input
              type="number"
              placeholder="Step Number"
              value={instruction.step_number}
              onChange={(e) => {
                const updatedInstructions = [...instructions];
                updatedInstructions[index].step_number = e.target.value;
                setInstructions(updatedInstructions);
              }}
              required
            />
            <input
              type="text"
              placeholder="Instruction"
              value={instruction.step}
              onChange={(e) => {
                const updatedInstructions = [...instructions];
                updatedInstructions[index].step = e.target.value;
                setInstructions(updatedInstructions);
              }}
              required
            />
            <button type="button" onClick={() => setInstructions(instructions.filter((_, i) => i !== index))}>
              Remove
            </button>
          </div>
        ))}
        <button type="button" onClick={() => setInstructions([...instructions, { step_number: "", step: "" }])}>
          Add Instruction
        </button>

        <button type="submit" disabled={loading}>
          {loading ? "Creating..." : "Create Recipe"}
        </button>
      </form>
    </div>
  );
};

export default CreateRecipe;
