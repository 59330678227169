import React, { useEffect } from "react";
import "./InstagramPost.css"; // Optional: Import the CSS file for styling

const InstagramPost = () => {
  useEffect(() => {
    // Dynamically load the LightWidget script when the component mounts
    const script = document.createElement('script');
    script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="instagram-widget-container">
      {/* Embed the LightWidget iframe */}
      <iframe
        src="https://cdn.lightwidget.com/widgets/32906d59af9c5b718fa1109800cbb974.html"
        scrolling="no"
        allowtransparency="true"
        className="lightwidget-widget"
        title="Instagram Feed"
        style={{ width: '100%', height: '500px', border: '0', overflow: 'hidden' }}
      ></iframe>
    </div>
  );
};

export default InstagramPost;
