import React from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp, FaCopy } from "react-icons/fa";
import "./SharePost.css";

const SharePost = ({ postLink }) => {
  const socialPlatforms = [
    {
      name: "Facebook",
      icon: <FaFacebookF className="icon" />,
      shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postLink)}`,
    },
    {
      name: "Twitter",
      icon: <FaTwitter className="icon" />,
      shareUrl: `https://twitter.com/intent/tweet?url=${encodeURIComponent(postLink)}&text=Check this out!`,
    },
    {
      name: "LinkedIn",
      icon: <FaLinkedinIn className="icon" />,
      shareUrl: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(postLink)}`,
    },
    {
      name: "WhatsApp",
      icon: <FaWhatsapp className="icon" />,
      shareUrl: `https://api.whatsapp.com/send?text=${encodeURIComponent(postLink)}`,
    },
  ];

  const copyToClipboard = () => {
    navigator.clipboard.writeText(postLink);
    alert("Link copied to clipboard!");
  };

  return (
    <div className="share-post">
      <p className="share-text">Share this post:</p>
      <div className="share-icons">
        {socialPlatforms.map((platform) => (
          <a
            key={platform.name}
            href={platform.shareUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="share-icon"
            title={`Share on ${platform.name}`}
          >
            {platform.icon}
          </a>
        ))}
        <button onClick={copyToClipboard} className="share-icon copy-link" title="Copy Link">
          <FaCopy className="icon" />
        </button>
      </div>
    </div>
  );
};

export default SharePost;
