import React, { useState, useEffect } from 'react';
import './RenderRecipe.css';  // Ensure the updated CSS is applied

import { API_BASE_URL } from '../../config'; // Add your API base URL if it's stored here
import RenderValuableInformation from './RenderValuableInformation';

const RenderRecipe = ({ recipe }) => {
  const { title, description, image } = recipe; // Assuming image contains the filename or identifier
  const [imgurl, setImgurl] = useState(null);  // State to store the image URL
  const [loading, setLoading] = useState(true);  // Loading state for the image
  const [error, setError] = useState(null);  // Error state for handling failures

  useEffect(() => {
    const fetchRecipeImage = async () => {
      try {
        // Fetch the image from your backend (adjust API endpoint as necessary)
        const response = await fetch(`${API_BASE_URL}/recipes/${image}.webp`);
        
        if (!response.ok) {
          throw new Error("Failed to fetch the image");
        }

        const imageUrl = await response.url; // Assuming the image is returned from the backend with a valid URL
        setImgurl(imageUrl); // Set the image URL in the state
        setLoading(false);   // Set loading to false after the image is fetched
      } catch (error) {
        setError(error.message);  // Set the error message if fetching fails
        setLoading(false);        // Set loading to false
      }
    };

    fetchRecipeImage();
  }, [image]); // Dependency on the `image` prop to refetch when it changes

  if (loading) {
    return <div>Loading...</div>; // Show a loading message or spinner while fetching
  }

  if (error) {
    return <div>Error: {error}</div>; // Show an error message if fetching fails
  }

  return (
    <div className="recipe-container">
      {/* Recipe Image */}
      {imgurl && <img src={imgurl} alt={title} className="recipe-image" />}
      
      {/* Recipe Title */}
      <h2 className="recipe-title">{title}</h2>

      {/* Recipe Description */}
      <p className="recipe-description">{description}</p>

      {/* Render Valuable Information */}
      <RenderValuableInformation valuableInfo={recipe.valuable_info} />
    </div>
  );
};

export default RenderRecipe;
