import React, { useState } from "react";
import ContactInfo from "./ContactInfo"
import ContactForm from "./ContactForm"
import LocationSection from "./LocationSection";

import PageHero from "../../components/pagehero/PageHero";
import img from "../../images/contactusimages/BackgroundTitleImage.jpg"
import "./Contact.css";

const Contact = () => {

  return (
    <>
      <PageHero
        image={img}
        description="Have questions about our services or need help choosing the right plan? We’d love to hear from you! Reach out anytime — whether it’s about bookings, nutrition guidance, or general support, we're just a message away."
      />

      <div className="contact-container">
       <ContactInfo />
       <ContactForm />
      </div>

      <LocationSection />
    </>
  );
};

export default Contact;