import React from "react";
import "./About.css";

// Background component
import PageHero from "../../components/pagehero/PageHero";
import AboutHeroImage from "../../images/aboutimages/BackgroundTitleImage.jpg";

// Sections
import AboutIntro from "./sections/AboutIntro";
import AboutJourney from "./sections/AboutJourney";
import AboutCTA from "./sections/AboutCTA";

const About = () => {
  return (
    <div className="about-container">
      <PageHero
        description="At e-nutritionist, we offer personalized nutrition and lifestyle guidance to help you reach your health goals. Founded by Thymios Arvanitis, our mission is to support your journey with expert advice, tailored plans, and real-world results."
        image={AboutHeroImage}
      />
      <AboutIntro />
      <AboutJourney />
      <AboutCTA />
    </div>
  );
};

export default About;
