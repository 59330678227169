import React, { useEffect } from "react";
import PageHero from "../../components/pagehero/PageHero";
import ServicePlans from "./ServicePlans"; // ✅ Import ServicePlans component
import backgroundImg from "../../images/servicesimages/BackgroundTitleImage.jpg";

import "./Services.css";

const Services = () => {
  useEffect(() => {
    // Set up the IntersectionObserver for all elements inside the services-info-container
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
            entry.target.classList.remove("lazy-load");
          }
        });
      },
      { threshold: 0.3 } // Trigger when 30% of the element is in view
    );

    // Select all elements inside .services-info-container
    const elements = document.querySelectorAll(".services-info-container .lazy-load");
    elements.forEach((el) => observer.observe(el));

    // Cleanup observer on component unmount
    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <>
      {/* Hero Section */}
      <PageHero
        image={backgroundImg}
        description="Explore our range of expert-led nutrition and wellness services, designed to help you eat better, move smarter, and feel your best—no matter your starting point."
      />

      {/* Services Grid Section */}
      <section className="services-grid">
        <h2 className="service-plans-title">Available Service Plans</h2>
        <ServicePlans /> {/* ✅ Load dynamic service plans here */}
      </section>

      {/* Service Description Section */}
      <section className="services-info-container">
        <div className="services-info">
          <h2 className="lazy-load">How Our Services Work</h2>
          <p className="lazy-load"><strong>Important Information for Your Consultations</strong></p>

          <p className="lazy-load">
            We offer a <strong>complimentary 15-minute introductory call</strong> before your first booking,
            allowing you to discuss your goals and find the perfect plan for your needs.
          </p>

          <p className="lazy-load">
            All consultations take place via <strong>Google Meet.</strong><strong>Continuous support between sessions</strong>
            is available 24h via WhatsApp, ensuring you always have easy communication with us.
          </p>

          <p className="lazy-load">
            <strong>Payment & Scheduling:</strong> Payments must be made via <strong>Pay-by-link</strong> at least
            <strong>24 hours before</strong> your scheduled session. If payment is not received in time, the session will be
            <strong>automatically canceled.</strong>
          </p>

          <p className="lazy-load">
            <strong>Cancellation & Rescheduling:</strong> Appointments may be rescheduled or canceled <strong>up to 24 hours in advance</strong>
            without additional fees.
          </p>
        </div>
      </section>
    </>
  );
};

export default Services;
