import React, { useEffect, useRef, useState } from "react";
import CTAButton from "../buttons/CTAButton";
import "./PageHero.css";

const PageHero = ({ image, title, description }) => {
  // Refs for title, description, and CTA
  const titleRef = useRef(null);
  const descRef = useRef(null);
  const ctaRef = useRef(null);

  // State for visibility
  const [titleVisible, setTitleVisible] = useState(false);
  const [descVisible, setDescVisible] = useState(false);
  const [ctaVisible, setCtaVisible] = useState(false);

  useEffect(() => {
    // Intersection Observer callback to toggle visibility
    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === titleRef.current) setTitleVisible(true);
          if (entry.target === descRef.current) setDescVisible(true);
          if (entry.target === ctaRef.current) setCtaVisible(true);

          observer.unobserve(entry.target); // Stop observing once the element is visible
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Adjust visibility threshold (50%)
    });

    // Observe each element
    if (titleRef.current) observer.observe(titleRef.current);
    if (descRef.current) observer.observe(descRef.current);
    if (ctaRef.current) observer.observe(ctaRef.current);

    // Cleanup observer when component unmounts
    return () => observer.disconnect();
  }, []);

  return (
    <section
      className={`page-hero ${description ? "with-description" : "no-description"}`}
    >
      <div
        className="page-hero-bg"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="overlay"></div>
      <div className="page-hero-content">
        {title && (
          <h1
            ref={titleRef}
            className={titleVisible ? "fade-in" : ""}
          >
            {title}
          </h1>
        )}
        {description && (
          <p
            ref={descRef}
            className={descVisible ? "fade-in-left" : ""}
          >
            {description}
          </p>
        )}
        <div
          ref={ctaRef}
          className={`cta-wrapper ${ctaVisible ? "fade-in" : ""}`}
        >
          <span className="cta-message">Ready to take the next step?</span>
          <CTAButton text="Book a Consultation" link="/book-consultation" />
        </div>
      </div>
    </section>
  );
};

export default PageHero;
