import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageHero from "../../components/pagehero/PageHero";
import RenderValuableInformation from "../../components/renderrecipe/RenderValuableInformation";

import Ingredients from './ingredients/Ingredients';
import Instructions from './instructions/Instructions';

import { API_BASE_URL } from '../../config';
import './FullRecipe.css';

const FullRecipe = () => {
  const { slug } = useParams();
  const [recipe, setRecipe] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/recipes/${slug}`);
        const data = await response.json();
        setRecipe(data);

        if (data.image) {
          setBackgroundImage(`${API_BASE_URL}/recipes/${data.image}.webp`);
        }
      } catch (error) {
        console.error("Error fetching recipe:", error);
      }
    };

    fetchRecipe();
  }, [slug]);

  if (!recipe) {
    return <p>Loading recipe...</p>;
  }
  return (
    <div className="full-recipe-container">
      <PageHero title={recipe.title} image={backgroundImage} />
      <div className="recipe-info-container">
        <RenderValuableInformation valuableInfo={recipe.valuable_info} />
        <img src={backgroundImage} alt={recipe.title} className="recipe-image-preview"/>
        <p className="description-of-recipe">{recipe.description}</p>
        <div className="steps-ingredients-container">
          <Ingredients ingredients={recipe.ingredients} />
          <Instructions instructions={recipe.instructions}/>
        </div>
      </div>
    </div>
  );
}

export default FullRecipe;
