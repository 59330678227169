import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Recipes.css";
import PageHero from "../../components/pagehero/PageHero";
import BackgroundImg from "../../images/recipesimages/background.jpg";
import RenderRecipe from "../../components/renderrecipe/RenderRecipe";
import { API_BASE_URL } from '../../config';

const Recipes = () => {
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/recipes`);
        const data = await response.json();
        setRecipes(data);
      } catch (error) {
        console.error("Error fetching recipes:", error);
      }
    };

    fetchRecipes();
  }, []);
  return (
    <div className="recipes-container">
      <PageHero 
        description="Explore a variety of easy, balanced, and goal-oriented recipes designed to fuel your body and satisfy your taste buds. Whether you're eating for performance, weight management, or simply wellness, you'll find meals that are as nourishing as they are delicious."
        image={BackgroundImg}
      />
      <div className="container-of-recipes">
        {recipes.length > 0 ? (
          recipes.map((recipe) => (
            <Link 
              key={recipe.slug}  // Using slug as the key
              to={`/fullrecipe/${recipe.slug}`}  // Link with slug
            >
              <RenderRecipe recipe={recipe} />
            </Link>
          ))
        ) : (
          <p>Loading recipes...</p>
        )}
      </div>
    </div>
  );
};

export default Recipes;
