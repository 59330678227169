import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'; // Import the WhatsApp icon

import './InstagramButton.css'; // Import custom styles

const InstagramButton = () => {
  const whatsappNumber = "+34613497305"; // Replace with your WhatsApp number
  const message = "Hello, I would like to inquire about your services!"; // Customize your default message

  return (
    <div className="whatsapp-button-container">
      <a 
        href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`} 
        target="_blank" 
        rel="noopener noreferrer"
        className="whatsapp-button"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" /> {/* WhatsApp icon */}
      </a>
    </div>
  );
};

export default InstagramButton;
