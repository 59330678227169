import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Blogs.css";
import PageHero from "../../components/pagehero/PageHero";
import SharePost from "../../components/posts/SharePost";
import Bckgrndttlimg from "../../images/blogsimages/test.jpg"
import { useLanguage } from "../../context/LanguageContext";
import { API_BASE_URL } from '../../config';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { selectedLanguage } = useLanguage();

  useEffect(() => {
    fetchBlogs(currentPage, selectedLanguage);
  }, [currentPage, selectedLanguage]);

  const fetchBlogs = async (page, language) => {
    setLoading(true);
    setError("");

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/blogs?language=${language}&page=${page}`);
      const data = await response.json();

      if (Array.isArray(data.blogs)) {
        setBlogs(data.blogs);
        setTotalPages(data.total_pages || 1); // Make sure your API returns `total_pages`
      } else {
        console.error("Unexpected API response:", data);
        setBlogs([]);
      }
    } catch (err) {
      console.error("Error fetching blogs:", err);
      setError("Failed to load blogs.");
      setBlogs([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageHero 
        description="Stay informed and inspired with expert-backed articles on nutrition, fitness, mindset, and healthy living. Discover practical tips and science-based insights to support your journey." 
        image={Bckgrndttlimg} 
      />

      <div className="preview-blogs">
        {error && <p className="error">{error}</p>}
        {loading && <p>Loading blogs...</p>}
        {!loading && blogs.length === 0 && <p>No blogs available.</p>}

        <ul className="preview-blogs-container">
          {blogs.map((blog) => (
            <li key={blog.id} className="preview-blog-item">
              <div className="preview-blog-image-section">
                <img
                  src={blog.image ? `${API_BASE_URL}/${blog.image}/profile-pic.webp` : "/images/default.jpg"}
                  alt={blog.title}
                  className="blog-image"
                />
                <p className="date-of-published">
                  {new Date(blog.published_at).toLocaleDateString(selectedLanguage, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
              <h2 className="preview-blog-title">{blog.title}</h2>
              <p className="preview-blog-description">{blog.description}</p>

              <SharePost postLink={`${window.location.origin}/blogs/${blog.slug}`} />
              <Link to={`/blogs/${blog.slug}`} className="cta-button">
                Read More
              </Link>
            </li>
          ))}
        </ul>

        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => prev - 1)}
            disabled={currentPage === 1}
          >
            ← Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev) => prev + 1)}
            disabled={currentPage >= totalPages || blogs.length === 0}
          >
            Next →
          </button>
        </div>
      </div>
    </>
  );
};

export default Blogs;
