import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../config";
import "./AdminGenerateToken.css";

const AdminGenerateToken = () => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Set minDate to the current date and time
  const [minDate, setMinDate] = useState("");

  useEffect(() => {
    // Get the current date and time in the required format (yyyy-MM-ddThh:mm)
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 16); // slice to remove seconds and milliseconds
    setMinDate(formattedDate);
  }, []);

  const handleGenerateToken = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const token = localStorage.getItem("adminToken");
    if (!token) {
      setError("You must be logged in to generate a token.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/download_tokens`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ download_token: { pdf_url: pdfUrl, expires_at: expiresAt } }),
      });

      const data = await response.json();
      if (response.ok) {
        setToken(data.token); // Set the generated token
        setError(""); // Clear previous errors
      } else {
        setError(data.errors || "Failed to generate token.");
      }
    } catch (error) {
      setError("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="generate-token-container">
      <h2>Generate Download Token</h2>
      {error && <p className="error">{error}</p>}
      {token && (
        <p>
          Your download link:{" "}
          <a
            href={`${API_BASE_URL}/pdf/${pdfUrl}.pdf?token=${token}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        </p>
      )}

      <form onSubmit={handleGenerateToken}>
        <div className="input-group">
          <label>PDF URL</label>
          <input
            type="text"
            value={pdfUrl}
            onChange={(e) => setPdfUrl(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <div className="input-group">
          <label>Expiration Date and Time (Optional)</label>
          <input
            type="datetime-local"
            value={expiresAt}
            onChange={(e) => setExpiresAt(e.target.value)}
            disabled={loading}
            min={minDate} // Prevent selecting past date and time
          />
        </div>

        <button type="submit" disabled={loading}>
          {loading ? "Generating..." : "Generate Token"}
        </button>
      </form>
    </div>
  );
};

export default AdminGenerateToken;
